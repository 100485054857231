import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);
gsap.config({ nullTargetWarn: false });

const Animations = () => {
  const click = useSelector((state) => state.animate.transition);

  // PIN NAVBAR

  useEffect(() => {
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        start: "top top",
        pin: ".gallery-navbar",
        end: "max",
        pinSpacing: false,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // MAKE SCROLLING SMOOTH FOR WHOLE PAGE

  useEffect(() => {
    let ctx = gsap.context(() => {
      let smoother = ScrollSmoother.create({
        content: ".app",
        smooth: 2,
        smoothTouch: 2,
        normalizeScroll: true,
        effects: true,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  }, []);

  // VITROCSA TITLE ANIMATION

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()
        .to("#left", {
          x: "-100vw",
          duration: 750,
        })
        .to(
          "#right",
          {
            x: "100vw",
            duration: 750,
          },
          "-=750"
        )
        .to(
          ".ellipse-1",
          {
            y: 0,
            duration: 500,
          },
          "-=750"
        )
        .to(
          ".ellipse-1",
          {
            bottom: "-50vw",
            left: "-25%",
            width: "150vw",
            height: "150vw",
            duration: 1000,
          },
          "-=250"
        )
        .to(
          ".gallery-navbar",
          {
            backgroundColor: "rgb(10,10,10)",
            duration: 100,
          },
          "-=900"
        )
        .to(
          ".gallery-burgerClass",
          {
            stroke: "white",
            duration: 100,
          },
          "-=900"
        )
        .to(
          ".navbar-text",
          {
            color: "white",
            duration: 100,
          },
          "-=900"
        )
        .set(
          ".logo-white",
          {
            display: "flex",
          },
          "-=900"
        )
        .set(
          ".logo-black",
          {
            display: "none",
          },
          "-=900"
        )
        .set(
          ".language-text",
          {
            color: "white",
            borderColor: "white",
          },
          "-=900"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".vitrocsa-trigger",
        start: "top 10%",
        pin: true,
        scrub: 1,
        pinSpacing: true,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // BLACK PAGE SCROLLER

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()
        .to(".section-2", {
          x: "-75%",
          y: "-1vw",
          scale: 1.5,
          duration: 2000,
        })
        .to(
          ".section-2-image-1",
          {
            y: "50%",
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".section-2-image-2",
          {
            y: "-10%",
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".section-1",
          {
            x: "-20vw",
            y: "-20%",
            scale: 0.7,
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".section-3",
          {
            y: "10%",
            duration: 2000,
          },
          "-=2000"
        )
        .to(".section-1", {
          y: "-200%",
          duration: 2000,
          zIndex: -1,
        })
        .to(
          ".section-2",
          {
            x: "-240%",
            scale: 1,
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".section-2-image-1",
          {
            y: 0,
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".section-2-image-2",
          {
            y: 0,
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".section-3",
          {
            x: 0,
            duration: 2000,
          },
          "-=2000"
        )
        .fromTo(
          ".section-3-img-container",
          {
            x: "100%",
          },
          {
            x: 0,
            duration: 2000,
          },
          "-=2000"
        )
        .to(".section-3-image-1", {
          marginRight: 0,
          width: "100%",
          height: "100%",
          duration: 2000,
        })
        .to(
          ".section-2",
          {
            y: "-100%",
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".gold-border",
          {
            borderColor: "rgb(10, 10, 10)",
            duration: 2000,
          },
          "-=2000"
        )
        .fromTo(
          ".section-3-image-2",
          { x: "120%" },
          {
            x: "0",
            duration: 2000,
          }
        )
        .to(".ellipse-2", {
          y: 0,
          duration: 1000,
        })
        .to(".ellipse-2", {
          bottom: "-50vw",
          left: "-25%",
          width: "150vw",
          height: "150vw",
          duration: 2000,
        })
        .to(
          ".gallery-navbar",
          {
            backgroundColor: "white",
            duration: 1500,
          },
          "-=2100"
        )
        .to(
          ".gallery-burgerClass",
          {
            stroke: "rgb(10,10,10)",
            duration: 1500,
          },
          "-=2100"
        )
        .to(
          ".navbar-text",
          {
            color: "black",
            duration: 1500,
          },
          "-=2100"
        )
        .set(
          ".logo-white",
          {
            display: "none",
          },
          "-=2100"
        )
        .set(
          ".logo-black",
          {
            display: "flex",
          },
          "-=2100"
        )
        .set(
          ".language-text",
          {
            color: "black",
            borderColor: "black",
          },
          "-=2100"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".black-page",
        start: "top top",
        pin: true,
        pinSpacing: true,
        invalidateOnRefresh: true,
        scrub: 1,
        end: () => 5500,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // WHITE PAGE SCROLLER

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()

        // Section 1 objects comes in

        .to(".section1-h1-class", {
          y: "0%",
          duration: 500,
        })
        .to(
          ".section1-h4-class",
          {
            y: "0%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-p-class",
          {
            y: "0%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-p-class-2",
          {
            y: "0%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-list",
          {
            y: "0%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-images",
          {
            y: "0%",
            duration: 500,
          },
          "-=500"
        )

        // Section 1 objects goes out

        .to(".section1-h1-class", {
          y: "-100%",
          duration: 500,
        })
        .to(
          ".section1-h4-class",
          {
            y: "-100%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-p-class",
          {
            y: "-100%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-p-class-2",
          {
            y: "-100%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-list",
          {
            y: "-100%",
            duration: 500,
          },
          "-=500"
        )

        // Change images

        .to(
          ".section1-image1",
          {
            y: "-110%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section1-image2",
          {
            width: "24rem",
            y: "-100%",
            opacity: 1,
            duration: 500,
          },
          "-=500"
        )

        // Section 2 comes in

        .to(
          ".second-section",
          {
            y: "-100%",
            duration: 500,
          },
          "-=800"
        )

        // Section 2 objects comes in

        .to(
          ".section2-h1-class",
          {
            y: "0%",
            duration: 500,
          },
          "-=200"
        )
        .to(
          ".section2-h4-class",
          {
            y: "0%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section2-p-class",
          {
            y: "0%",
            duration: 500,
          },
          "-=500"
        )

        // Image dissapearing

        .to(".section1-image2", {
          width: "12rem",
          opacity: 0.5,
          y: "-200%",
          duration: 500,
        })

        // Video comes in %25

        .fromTo(
          ".video",
          {
            y: "250%",
          },
          {
            y: "120%",
            duration: 500,
            // ease: "Expo.easeOut",
          },
          "-=500"
        )

        // Section 2 objects goes out

        .to(
          ".second-section",
          {
            y: "-110%",
            duration: 500,
          },
          "-=500"
        )
        .to(".section1-image2", {
          y: "-350%",
          duration: 500,
        })
        .to(
          ".section2-h1-class",
          {
            y: "-100%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section2-h4-class",
          {
            y: "-100%",
            duration: 500,
          },
          "-=500"
        )
        .to(
          ".section2-p-class",
          {
            y: "-100%",
            duration: 500,
          },
          "-=500"
        )
        // Fullscreen to video

        .to(
          ".video",
          {
            y: "-10%",
            width: "100vw",
            height: "40vw",
            left: "0%",
            duration: 750,
          },
          "-=500"
        )
        // Make default sizes to video

        .to(".video", {
          y: "-75%",
          width: "45vw",
          height: "20vw",
          left: "30%",
          duration: 500,
        })

        .to(".video", {
          y: "-200%",
          duration: 500,
        })

        .to(
          ".first-slider",
          {
            x: "7.5%",
            duration: 1000,
          },
          "-=400"
        )
        .to(".first-slider .h1-class", {
          y: "-100%",
          duration: 1000,
        })
        .to(
          ".first-slider .h4-class",
          {
            y: "100%",
            duration: 1000,
          },
          "-=1000"
        )
        .to(
          ".first-slider .image",
          {
            y: "100%",
            duration: 1000,
          },
          "-=1000"
        )
        .to(
          ".second-slider",
          {
            x: "7.5%",
            duration: 1000,
          },
          "-=500"
        )
        .to(".second-slider .h1-class", {
          y: "-100%",
          duration: 1000,
        })
        .to(
          ".second-slider .h4-class",
          {
            y: "100%",
            duration: 1000,
          },
          "-=1000"
        )
        .to(
          ".second-slider .image",
          {
            y: "100%",
            duration: 1000,
          },
          "-=1000"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".white-page",
        start: "top 20%",
        pin: true,
        scrub: 1,
        pinSpacing: true,
        invalidateOnRefresh: true,
        end: () => 10000,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // MAP SCROLLER

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set(".europe", { drawSVG: "0% 0%" });
      gsap.set(".world", { drawSVG: "0% 0%", fill: "none" });
      gsap.set(".location-europe", { drawSVG: "0% 0%" });
      gsap.set(".location-world", { drawSVG: "0% 0%" });
      gsap.set(".location-world-second", { drawSVG: "0% 0%" });
      const tl = gsap
        .timeline()

        // ANIMATE EUROPE AND TEXT

        .to(".europe", {
          drawSVG: "100%",

          transformOrigin: "center",
          duration: 2000,
        })
        .to(
          ".europe",
          {
            fill: "#9d8e68",
            duration: 1500,
          },
          "-=500"
        )
        .to(
          ".section4-p-class",
          {
            y: "0%",
            duration: 2000,
          },
          "-=1500"
        )

        // ANIMATE LOCATION

        .to(
          ".location-europe",
          {
            drawSVG: "100%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=1000"
        )
        .to(
          ".location-europe",
          {
            fill: "#9d8e68",
            duration: 1500,
          },
          "-=1000"

          // HIDE EUROPE WITH TEXT
        )
        .to(".europe", {
          fill: "white",
          duration: 2000,
        })
        .to(
          ".europe",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=1500"
        )
        .to(
          ".section4-p-class",
          {
            y: "-100%",
            duration: 1700,
          },
          "-=1500"
        )
        .set(".section-4-paragraphDivClass", {
          display: "none",
        })

        // HIDE LOCATION

        .to(
          ".location-europe",
          {
            fill: "white",
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".location-europe",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=2000"
        )

        // SHOW NEXT TEXTS

        .set(".section-4-paragraphDivClass-2", {
          display: "block",
        })
        .set(".section-4-paragraphDivClass-3", {
          display: "block",
        })

        // HIDE EU SVG

        .set(".europe-svg", {
          display: "none",
        })

        // SHOW WORLD SVG

        .set(".world-svg", {
          display: "block",
        })

        // ANIMATE WORLD SVG

        .to(".world", {
          drawSVG: "100%",
          duration: 2500,
        })
        .to(
          ".world",
          {
            fill: "#9d8e68",
            duration: 1000,
          },
          "-=1000"
        )

        // ANIMATE NEXT TEXTS

        .to(
          ".section4-p-class-2",
          {
            y: "0%",
            duration: 2000,
          },
          "-=2000"
        )

        // ANIMATE LOCATION

        .to(
          ".location-world",
          {
            drawSVG: "100%",

            transformOrigin: "center",
            duration: 2000,
          }
          // "-=500"
        )
        .to(
          ".location-world",
          {
            fill: "#9d8e68",
            duration: 1500,
          },
          "-=500"
        )

        // ANIMATE LAST TEXT

        .to(".section4-p-class-3", {
          y: "0%",
          duration: 2000,
        })

        // ANIMATE LOCATION

        .to(
          ".location-world-second",
          {
            drawSVG: "100%",

            duration: 2000,
          },
          "-=1000"
        )
        .to(".location-world-second", {
          fill: "#9d8e68",
          duration: 1000,
        })

        // HIDE WORLD SVG

        .to(".world", {
          fill: "white",
          duration: 2000,
        })
        .to(
          ".world",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=1500"
        )

        // HIDE LOCATION

        .to(
          ".location-world",
          {
            fill: "none",
            duration: 2000,
          },
          "-=1700"
        )
        .to(
          ".location-world",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=1500"
        )
        .to(
          ".location-world-second",
          {
            fill: "none",
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".location-world-second",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=1500"
        )

        // HIDE TEXT

        .to(
          ".section4-p-class-2",
          {
            y: "-100%",
            duration: 2000,
          },
          "-=2500"
        )

        .to(
          ".section4-p-class-3",
          {
            y: "-100%",
            duration: 2000,
          },
          "-=1500"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".map-page",
        start: "top 20%",
        pin: true,
        scrub: 1,
        toggleActions: "play none none reverse",
        invalidateOnRefresh: true,
        end: "+=4000px",
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // IMAGE SLIDER SCROLLER

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()
        .to(".third-slider", {
          x: "0%",
          duration: 4,
        })

        .to(".third-slider .h1-class", {
          y: "-100%",
          duration: 2,
        })
        .to(
          ".third-slider .h4-class",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(
          ".third-slider p",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(
          ".third-slider .image",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(".fourth-slider", {
          x: "0%",
          duration: 4,
        })
        .to(".fourth-slider .h1-class", {
          y: "-100%",
          duration: 2,
        })
        .to(
          ".fourth-slider .h4-class",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(
          ".fourth-slider p",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(
          ".fourth-slider .image",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(".fifth-slider", {
          x: "0%",
          duration: 4,
        })
        .to(".fifth-slider .h1-class", {
          y: "-100%",
          duration: 2,
        })
        .to(
          ".fifth-slider .h4-class",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(
          ".fifth-slider .image",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(".sixth-slider", {
          x: "0%",
          duration: 4,
        })
        .to(".sixth-slider .h1-class", {
          y: "-100%",
          duration: 2,
        })
        .to(
          ".sixth-slider .h4-class",
          {
            y: "100%",
            duration: 2,
          },
          "-=2"
        )
        .to(
          ".sixth-slider p",
          {
            y: "-100%",
            duration: 2,
          },
          "-=2"
        )
        .to(
          ".sixth-slider .image",
          {
            width: "85vw",
            height: "40vw",
            marginRight: "30%",
            position: "absolute",
            duration: 2,
          },
          "-=1.5"
        )
        .to(".sixth-slider .image", {
          width: "35vw",
          height: "15vw",
          marginRight: "30%",
          marginBottom: "40%",
          position: "absolute",
          duration: 2,
        });

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".slider",
        start: "top 5%",
        pin: true,
        scrub: 4,
        invalidateOnRefresh: true,
        pinSpacer: true,
        end: () => 20000,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // SECOND MAP SCROLLER

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set(".location-world", { drawSVG: "0% 0%" });
      gsap.set(".location-world-second", { drawSVG: "0% 0%" });
      gsap.set(".world", { drawSVG: "0% 0%", fill: "none" });
      const tl = gsap
        .timeline()

        // ANIMATE WORLD SVG

        .to(".world", {
          drawSVG: "100%",
          transformOrigin: "center",
          duration: 2000,
        })
        .to(
          ".world",
          {
            fill: "#9d8e68",
            duration: 1500,
          },
          "-=1500"
        )

        // ANIMATE NEXT TEXTS

        .to(
          ".section-4-paragraph",
          {
            y: "0%",
            duration: 1500,
            ease: "Expo.easeOut",
          },
          "-=1500"
        )

        // ANIMATE LOCATION

        .to(".location-world-second", {
          drawSVG: "100%",
          transformOrigin: "center",
          duration: 1500,
        })
        // .fromTo(
        //   ".location-africa",
        //   { y: "100%", x: "100%" },
        //   {
        //     y: "0%",
        //     x: "0%",
        //     duration: 1500,
        //   },
        //   "-1500"
        // )
        .to(
          ".location-world",
          {
            drawSVG: "100%",
            transformOrigin: "center",
            duration: 1500,
          },
          "-=1500"
        )
        .to(".location-world-second", {
          fill: "#9d8e68",
          duration: 1000,
        })
        .to(
          ".location-world",
          {
            fill: "#9d8e68",
            duration: 1000,
          },
          "-=1000"
        )
        // HIDE WORLD SVG

        .to(".world", {
          fill: "white",
          duration: 1500,
        })
        .to(
          ".world",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 1500,
          },
          "-=1500"
        )

        // HIDE LOCATION

        .to(
          ".location-world",
          {
            fill: "white",
            duration: 2000,
          },
          "-=1700"
        )
        .to(
          ".location-world-second",
          {
            fill: "white",
            duration: 2000,
          },
          "-=2000"
        )
        .to(
          ".location-world",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=1500"
        )
        .to(
          ".location-world-second",
          {
            drawSVG: "0%",

            transformOrigin: "center",
            duration: 2000,
          },
          "-=1500"
        )

        // HIDE TEXT

        .to(
          ".section-4-paragraph",
          {
            y: "-100%",
            duration: 2000,
          },
          "-=1500"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".map-page-2",
        start: "top 20%",
        pin: true,
        end: "+=3000px",
        scrub: 1,
        toggleActions: "play none none reverse",
        invalidateOnRefresh: true,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // HORIZONTAL SCROLLER

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()

        // FIRST SECTION COMES IN

        .to(".horizontal-first", {
          x: "0%",
          duration: 5000,
        })
        .to(".horizontal-first", {
          x: "-100%",
          duration: 5000,
        })
        .to(
          ".horizontal-second",
          {
            x: "0%",
            duration: 5000,
          },
          "-=5000"
        )
        .to(".horizontal-second", {
          y: "100%",
          x: "-100%",
          duration: 5000,
        })
        .to(
          ".horizontal-third",
          {
            x: "0%",
            y: "0",
            duration: 5000,
          },
          "-=5000"
        )
        .to(".horizontal-third", {
          x: "-40%",
          duration: 5000,
        })
        .to(
          ".horizontal-fourth",
          {
            x: "25%",
            y: "45%",
            duration: 5000,
          },
          "-=5000"
        )
        .to(".horizontal-third", {
          x: "-150%",
          duration: 5000,
        })
        .to(
          ".horizontal-fourth",
          {
            x: "-10%",
            y: "0",
            duration: 5000,
          },
          "-=5000"
        )
        .to(
          ".horizontal-fourth-section-2",
          {
            x: "-30%",
            duration: 5000,
          },
          "-=5000"
        )
        .to(".horizontal-fourth", {
          x: "-50%",
          y: "0",
          duration: 5000,
        })
        .to(
          ".horizontal-fourth-section-2",
          {
            x: "0%",
            duration: 5000,
          },
          "-=5000"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".horizontal-page",
        start: "top top",
        pin: true,
        end: "+=4000px",
        pinSpacing: true,
        scrub: 3,
        toggleActions: "play none none reverse",
        invalidateOnRefresh: true,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  // FOOTER SCROLLER

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()

        // FIRST SECTION COMES IN

        .fromTo(
          ".badge",
          { rotate: 300, y: "300%" },
          {
            rotate: 0,
            y: "0%",
            duration: 1000,
          }
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".footer-first",
        start: "top top",
        pin: true,
        pinSpacing: true,
        scrub: 3,
        toggleActions: "play none none reverse",
        invalidateOnRefresh: true,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()

        // FIRST SECTION COMES IN

        .to(".footer-section-2", {
          x: "-100%",
          duration: 5000,
        })
        .to(
          ".footer-third",
          {
            x: "0%",
            duration: 5000,
          },
          "-=5000"
        )
        .to(".footer-third h1", {
          y: "100%",
          duration: 5000,
          ease: "Expo.easeInOut",
        })
        .to(
          ".footer-third h4",
          {
            y: "100%",
            duration: 5000,
            ease: "Expo.easeInOut",
          },
          "-=5000"
        )
        .to(
          ".footer-third p",
          {
            y: "-100%",
            duration: 5000,
            ease: "Expo.easeInOut",
          },
          "-=5000"
        )
        .to(
          ".footer-third .border-div",
          {
            opacity: "0",
            duration: 5000,
            ease: "Expo.easeInOut",
          },
          "-=5000"
        )
        .set(".white-div-left", {
          opacity: 1,
        })
        .set(".white-div-right", {
          opacity: 1,
        })
        .to(".footer-third video", {
          width: "110vw",
          marginRight: "12vw",
          marginBottom: "5vw",
          duration: 7000,
        })
        .to(".footer-third .white-div-left", {
          x: "30%",
          duration: 4000,
        })
        .to(
          ".footer-third .white-div-right",
          {
            x: "-20%",
            duration: 4000,
          },
          "-=4000"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".footer-second",
        start: "top top",
        pin: true,
        invalidateOnRefresh: true,
        pinSpacing: true,
        scrub: 3,
        toggleActions: "play none none reverse",
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap
        .timeline()

        // FIRST SECTION COMES IN

        .to(".thankyou-div-left", {
          x: "-30%",
          duration: 1,
        })
        .to(
          ".thankyou-div-right",
          {
            x: "30%",
            duration: 1,
          },
          "-=1"
        );

      let horizontalScroll = ScrollTrigger.create({
        animation: tl,
        trigger: ".thankyou",
        start: "top center",
        pin: true,
        pinSpacing: true,
        scrub: 1,
        toggleActions: "play none none reverse",
        invalidateOnRefresh: true,
      });
    });
    return () => ctx.revert(); // <-- CLEANUP!
  });
};

export default Animations;
