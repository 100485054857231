import React from "react";
import { useSelector } from "react-redux";

import Slider from "./Slider";

const SliderContainer = () => {
  const language = useSelector((state) => state.language.language);

  return (
    <div className="slider h-[80vh] lg:h-[100vh] w-full lg:-translate-y-full">
      <Slider
        mainClass="third-slider flex-col lg:translate-x-full gap-10 lg:gap-0 "
        image="2007/2007.png"
        h1={language[1].title2007}
        leftDiv="justify-center"
        h1Class="w-[24rem] text-3xl text-center lg:text-left"
        h4Class="text-center lg:text-left"
        paragraphClass="text-center lg:text-left w-full text-xl lg:text-2xl"
        h4="2007"
        p={language[1].text2007}
      />
      <Slider
        mainClass="fourth-slider hidden lg:flex lg:translate-x-full absolute lg:-translate-y-[100%]"
        image="2008/2008.png"
        leftDiv="justify-center"
        h1={language[1].title2010}
        h1Class="w-[30rem]"
        h4="2010"
        p={language[1].text2010}
      />
      <Slider
        mainClass="fifth-slider hidden lg:flex lg:translate-x-full absolute lg:-translate-y-[100%]"
        image="2011/2011.png"
        leftDiv="justify-center"
        h1={language[1].title2011}
        h1Class="w-[30rem]"
        h4="2011"
      />
      <Slider
        mainClass="sixth-slider hidden lg:flex lg:translate-x-full absolute lg:-translate-y-[100%]"
        video={true}
        leftDiv="justify-start"
        h1={language[1].title2012}
        h1Class="w-[28rem]"
        paragraphClass="w-[25rem] opacity-40 lg:mb-72"
        h4="2012"
        p={language[1].text2012}
      />
    </div>
  );
};

export default SliderContainer;
