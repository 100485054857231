import React from "react";
import { useSelector } from "react-redux";
import Section from "../../Reusable/Section";
import Text from "../../Reusable/Text";

const First = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <Section
      mainClass="first-section flex-col-reverse h-[125vh] lg:h-[100vh] lg:gap-0 gap-72"
      leftClass="flex justify-center lg:justify-end"
      rightClass="flex h-full justify-center items-center"
      right={
        <>
          <div className=" mt-20 w-full lg:w-5/6 flex flex-col gap-2 lg:gap-0">
            <Text
              h4DivClass="h-8 flex justify-center lg:justify-start"
              h1DivClass="h-60 w-full lg:w-35rem mt-5 text-3xl"
              paragraphDivClass="h-8 w-full"
              h4Class="section1-h4-class lg:-translate-y-full"
              h1Class="section1-h1-class text-center lg:-translate-y-full"
              paragraphClass="section1-p-class text-center lg:text-left lg:-translate-y-full"
              h4="1994"
              h1={language[1].title1994}
              p={language[1].text1994}
            />
            <Text
              paragraphDivClass="mt-2 text-grey flex justify-center lg:justify-start"
              paragraphClass="section1-p-class-2 text-center w-8/12 lg:text-left text-lg lg:text-2xl lg:-translate-y-full"
              p={language[1].subtext1994}
            />
            <div className="h-10 lg:overflow-hidden mt-10">
              <ul className="flex flex-col lg:flex-row text-2xl gap-5 items-center lg:items-tart lg:gap-16 section1-list lg:-translate-y-full">
                <li className="flex">
                  <div className="font-black uppercase lg:normal-case	">
                    Vitr
                  </div>
                  <div>es</div>
                </li>
                <li className="first-letter:font-black w-32"> Orchidées </li>
                <li className="first-letter:font-black w-32"> Constructions</li>
                <li className="font-black w-32"> SA </li>
              </ul>
            </div>
          </div>
        </>
      }
      left={
        <>
          <div className="h-fit lg:h-[25rem] w-full lg:w-3/4 lg:overflow-hidden flex justify-center ">
            <div className="flex flex-col w-full gap-10 section1-images lg:-translate-y-full">
              <img
                className="hidden lg:flex w-96 section1-image1"
                src="/images/1994/1994.png"
              />
              <img
                className="w-full lg:w-60 object-cover lg:opacity-40 section1-image2"
                src="/images/1996/1996.png"
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default First;
