import { createSlice } from "@reduxjs/toolkit";

const initialAnimateState = { transition: false };

const animateSlice = createSlice({
  name: "Animate",
  initialState: initialAnimateState,
  reducers: {
    animateTransition(state, action) {
      // if (action.payload == false) {
      //   state.transition = false;
      // } else {
      state.transition = !state.transition;
      // }
    },
  },
});

export const { animateTransition } = animateSlice.actions;

export default animateSlice.reducer;
