import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeLanguage } from "./Redux/store/language";
import { useSelector } from "react-redux";

const MobileWarning = ({ languageMobile }) => {
    const language = useSelector((state) => state.language.language);
    const dispatch = useDispatch();

  useEffect(() => {
    if (languageMobile === "de") {
      dispatch(
        changeLanguage({
          en: false,
          fr: false,
          de: true,
          es: false,
        })
      );
    }
    if (languageMobile === "en") {
      dispatch(
        changeLanguage({
          en: true,
          fr: false,
          de: false,
          es: false,
        })
      );
    }
    if (languageMobile === "fr") {
      dispatch(
        changeLanguage({
          en: false,
          fr: true,
          de: false,
          es: false,
        })
      );
    }
    if (languageMobile === "es") {
      dispatch(
        changeLanguage({
          en: false,
          fr: false,
          de: false,
          es: true,
        })
      );
    }
  });

    
        return (
            <div className={`h-screen flex justify-center items-center flex-col`}>
                <p>{language[1].messageMobile}</p>
                <a href="https://vitrocsa.com/" className={`text-[#9D8E68]`}>{language[1].backButton}</a>
            </div>
        )
}

export default MobileWarning;