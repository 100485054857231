import React from "react";
import Four from "./Four";

const SecondMap = () => {
  return (
    <div className="map-page-2 w-full h-[60vh] lg:h-[100vh] lg:-translate-y-[50vw]">
      <Four />
    </div>
  );
};

export default SecondMap;
