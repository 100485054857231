import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../Redux/store/language";

import Navbar from "../Reusable/Navbar";
import Scene from "./Scene";
// Sections

import VitrocsaTitle from "../Sections/Vitrocsa-Title-Section/Vitrocsa";
import BlackSection from "../Sections/Black-Section/Black";
import WhiteSection from "../Sections/White-Section/White";
import FirstMap from "../Sections/Map-Sections/First-Map";
import SliderContainer from "../Sections/Slider-Section/Container";
import SecondMap from "../Sections/Map-Sections/Second-Map";
import HorizontalSlider from "../Sections/Hortizontal-Section/Horizontal";
import Footer from "../Sections/Footer-Section/Footer";

const Gallery = ({ language }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (language === "de") {
      dispatch(
        changeLanguage({
          en: false,
          fr: false,
          de: true,
          es: false,
        })
      );
    }
    if (language === "en") {
      dispatch(
        changeLanguage({
          en: true,
          fr: false,
          de: false,
          es: false,
        })
      );
    }
    if (language === "fr") {
      dispatch(
        changeLanguage({
          en: false,
          fr: true,
          de: false,
          es: false,
        })
      );
    }
    if (language === "es") {
      dispatch(
        changeLanguage({
          en: false,
          fr: false,
          de: false,
          es: true,
        })
      );
    }
  });

  return (
    <div className="gallery overflow-hidden z-40">
      <Navbar
        navClass="bg-white gallery-navbar"
        logoWhite="logo-white"
        logoBlack="logo-black"
        burgerClass="visible stroke-black xl:mr-4 gallery-burgerClass"
        navbarElements="translate-y-full"
        navbarText="hidden lg:flex"
      />
      <Scene />
      <VitrocsaTitle />
      <BlackSection />
      <WhiteSection />
      <FirstMap />
      <SliderContainer />
      <SecondMap />
      <HorizontalSlider />
      <Footer />
    </div>
  );
};

export default Gallery;
