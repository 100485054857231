import React from "react";
import { useSelector } from "react-redux";

// Sections

import Video from "./Video";
import First from "./First";
import Second from "./Second";
import Slider from "./Slider";

const WhiteSection = () => {
  const language = useSelector((state) => state.language.language);

  return (
    <div className="lg:gap-0 lg:h-[150vh] w-full white-page flex flex-col items-center">
      <First />
      <Second />
      <Video />
      <Slider
        mainClass="first-slider flex-col h-[75vh] lg:translate-x-full lg:-translate-y-[150%]"
        image="2001/2001.png"
        h1={language[1].title2001}
        h4Class="text-center"
        h1Class="text-center text-3xl lg:w-[25rem] h-44"
        h4="2001"
        paragraphClass="hidden"
      />
      <Slider
        mainClass="second-slider h-[75vh] flex-col lg:translate-x-full lg:absolute lg:-translate-y-[15%]"
        image="2002/2002.png"
        h1={language[1].title2002}
        h4Class="text-center"
        h1Class={`text-center text-3xl h-40 ${
          language[0].de ? "w-[35rem]" : "w-[20rem]"
        }`}
        h4="2002"
        paragraphClass="hidden"
      />
    </div>
  );
};

export default WhiteSection;
