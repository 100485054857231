import React from "react";

const Section = ({ mainClass, left, right, leftClass, rightClass }) => {
  return (
    <main
      className={`${mainClass} flex lg:flex-row justify-center items-center w-full lg:h-[100vh] lg:px-24 lg:py-10`}
    >
      <section className={`${leftClass} lg:grow lg:h-5/6`}>{left}</section>
      <section className={`${rightClass} lg:w-7/12 lg:h-5/6`}> {right}</section>
    </main>
  );
};

export default Section;
