import React from "react";
import { World } from "./World";
import Section from "../../Reusable/Section";
import { useSelector } from "react-redux";

const Four = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <Section
      mainClass="four-section flex-col"
      // leftClass="flex justify-end"
      leftClass="flex h-full justify-center items-center"
      rightClass="flex justify-center pt-20"
      left={
        <>
          <div className="h-4/6 lg:w-3/6 flex flex-col">
            <div className="lg:overflow-hidden">
              <div
                className={`text-2xl text-center lg:text-left h-50 lg:w-[17rem] lg:mb-12 section-4-paragraph lg:-translate-y-full`}
              >
                <div className="font-bold">2013</div>
                {language[1].titre13}
              </div>
            </div>
          </div>
        </>
      }
      right={
        <div>
          <img src="/images/map/map.png" className="flex lg:hidden" />
          <World />
        </div>
      }
    />
  );
};

export default Four;
