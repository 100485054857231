import React from "react";
import { useSelector } from "react-redux";
import Section from "../../Reusable/Section";
import Text from "../../Reusable/Text";

const Second = () => {
  const language = useSelector((state) => state.language.language);

  return (
    <Section
      mainClass="flex-col-reverse"
      leftClass="hidden lg:flex"
      rightClass="flex justify-center items-center second-section"
      right={
        <div className="h-full mb-20 w-11/12 lg:w-5/6 flex flex-col">
          <Text
            h4DivClass="h-8 "
            h1DivClass="h-40 text-3xl lg:w-35rem mt-5"
            paragraphDivClass="h-20 text-xl mt-4 lg:mt-0 lg:w-[35rem]"
            h4Class="section2-h4-class text-center lg:text-left lg:-translate-y-full"
            h1Class="section2-h1-class text-center lg:text-left lg:-translate-y-full"
            paragraphClass="section2-p-class text-center lg:text-left lg:-translate-y-full"
            h4="1996"
            h1={language[1].title1996}
            p={language[1].text1996}
          />
        </div>
      }
    />
  );
};

export default Second;
