import React from "react";
import Section from "../../Reusable/Section";
import Text from "../../Reusable/Text";
import { World } from "./World";
import Europe from "./Europe";
import { useSelector } from "react-redux";

const Content = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <Section
      mainClass="four-section flex-col"
      leftClass="flex h-full justify-center items-center"
      rightClass="flex justify-center pt-20"
      left={
        <>
          <div className="w-full lg:h-4/6 gap-20 lg:gap-0 lg:w-3/6 flex flex-col items-center lg:items-start">
            <Text
              textMainClass="lg:ml-20 w-full flex justify-center lg:justify-start"
              paragraphDivClass="h-54 w-11/12 text-center lg:text-left lg:w-[14.6rem] section-4-paragraphDivClass"
              paragraphClass="section4-p-class lg:-translate-y-full"
              p={language[1].descriptionMap1}
            />
            <div
              className={`overflow-hidden text-2xl h-54 w-[24rem] lg:w-[15rem] lg:hidden section-4-paragraphDivClass-2 lg:mb-12`}
            >
              <div
                className={`section4-p-class-2 text-center flex flex-col gap-2 lg:block lg:gap-0 lg:text-left lg:translate-y-full`}
              >
                <div className="font-bold">{language[1].titre0406} </div>{" "}
                {language[1].description0406}
              </div>
            </div>
            <div
              className={`overflow-hidden text-2xl h-54 w-[24rem] lg:w-[15rem]  lg:hidden section-4-paragraphDivClass-3`}
            >
              <div
                className={`section4-p-class-3 lg:translate-y-full text-center lg:text-left flex flex-col gap-2 lg:block lg:gap-0`}
              >
                <div className="font-bold">{language[1].titre0810}</div>
                {language[1].description0810}
              </div>
            </div>
          </div>
        </>
      }
      right={
        <div>
          <img src="/images/map/map.png" className="flex lg:hidden" />
          <Europe />
          <World />
        </div>
      }
    />
  );
};

export default Content;
