import React from "react";

import Content from "./Content";

const FirstMap = () => {
  return (
    <div className="map-page w-full h-[115vh] lg:h-[100vh] lg:-translate-y-[40vw]">
      <Content />
    </div>
  );
};

export default FirstMap;
