import React from "react";

const Text = ({
  h4,
  h1,
  h4Class,
  h1Class,
  h1DivClass,
  h4DivClass,
  p,
  paragraphDivClass,
  paragraphClass,
  textMainClass,
}) => {
  return (
    <div className={`${textMainClass}`}>
      <div className={`${h4DivClass} overflow-hidden text-2xl `}>
        <h4 className={`${h4Class} lg:text-left  text-gold`}>{h4}</h4>
      </div>
      <div
        className={`${h1DivClass} overflow-hidden lg:text-4xl leading-tight`}
      >
        <h1 className={`${h1Class} lg:text-left font-black uppercase `}>
          {h1}
        </h1>
      </div>
      <div className={`${paragraphDivClass} overflow-hidden text-2xl `}>
        <p className={`${paragraphClass}`}>{p}</p>
      </div>
    </div>
  );
};

export default Text;
