import React from "react";

const Video = ({ videoClass }) => {
  return (
    <video
      className={`${videoClass} lg:w-[40vw] mt-10 lg:mt-0 lg:h-[20vw] lg:absolute lg:top-0 left-[39%] video`}
      autoPlay
      muted
    >
      <source src="videos/vitrocsa-1.mp4" type="video/mp4" />
    </video>
    // <img
    //   src="/images/video/video.png"
    //   className={`${videoClass} w-[40vw] h-[20vw] bg-red-500 absolute top-0 left-[39%] translate-y-[100vw] video`}
    // ></img>
  );
};

export default Video;
