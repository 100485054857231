import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

const Loader = ({ show }) => {
  const loading = useRef();
  const [loader, setLoader] = useState(1);
  useEffect(() => {
    if (loader < 100) {
      const timer = setInterval(() => {
        setLoader((oldCount) => oldCount + 1);
      }, 50);
      if (loader > 25) {
        show(true);
      }
      return () => clearInterval(timer);
    } else {
      gsap.to(loading.current, {
        x: "100%",
        ease: "Expo.easeInOut",
        duration: 2,
      });
      gsap.set(loading.current, {
        delay: 2,
        display: "none",
      });
    }
  }, [loader]);

  return (
    <div
      ref={loading}
      className="bg-black min-h-screen z-50 w-full absolute text-white flex flex-col justify-center items-center uppercase text-2xl font-italiana"
    >
      Loading
      <div>{loader}</div>
    </div>
  );
};

export default Loader;
