import React from "react";
import Text from "../../Reusable/Text";
import { useSelector } from "react-redux";

const Second = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <section className="horizontal-second gap-10 lg:gap-0 lg:h-[75vh] lg:absolute flex-col lg:flex-row flex lg:min-h-screen w-100vw lg:translate-x-full">
      <div className="w-full lg:w-6/12 h-1/4 lg:h-full flex items-center justify-center lg:justify-end lg:pr-32">
        <Text
          h4DivClass={`h-8 text-center lg:text-left`}
          h1DivClass={`lg:h-48 lg:w-[25rem] mt-5 text-center text-3xl lg:text-left`}
          paragraphDivClass={`hidden`}
          h4Class="h4-class"
          h1Class="h1-class"
          h4={"2015"}
          h1={language[1].titre15}
        />
      </div>
      <div className="w-full lg:w-3/4 lg:grow flex justify-start items-center">
        <img
          className="w-full lg:w-2/3 lg:mt-12 "
          src="/images/2015/2015.jpg"
        />
      </div>
    </section>
  );
};

export default Second;
