import React from "react";

const Section = ({ mainClass, left, right, leftClass, rightClass }) => {
  return (
    <main
      className={`${mainClass} flex lg:flex-row justify-center items-center w-full lg:h-[100vh]`}
    >
      <section className={`${leftClass} w-full lg:w-1/3 h-full `}>
        {left}
      </section>
      <section className={`${rightClass} w-full lg:w-2/3 h-full`}>
        {right}
      </section>
    </main>
  );
};

export default Section;
