import React from "react";
import Text from "../../Reusable/Text";
import { useSelector } from "react-redux";

const Third = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <section className="footer-third mt-20 lg:mt-0 flex items-start flex-col lg:flex-row lg:h-3/4 h-2/4 lg:grow lg:translate-x-full lg:-translate-y-72">
      <div className="absolute flex border-div w-11/12 lg:w-[60rem] h-2/3 ml-4 lg:ml-0 lg:h-3/5 border-2 -mt-4 lg:mt-0 border-gold lg:left-[2%] xl:left-[5%] 2xl:left-[15%] lg:top-44" />
      <div className="absolute hidden lg:block white-div-left w-5/12 opacity-0 -translate-x-full h-full bg-white z-30" />
      <div className="absolute hidden lg:block white-div-right w-5/12 opacity-0 translate-x-full h-full bg-white z-30 right-0" />
      <div className="lg:w-5/12 lg:min-h-screen flex items-center justify-end lg:pb-20">
        <Text
          h4DivClass="h-8 text-center lg:text-left "
          h1DivClass="text-center lg:text-left text-2.5xl flex justify-center lg:block lg:w-[25rem] mt-5"
          paragraphDivClass="flex justify-center lg:block lg:w-[25rem] lg:text-2xl text-lg mt-5 text-center lg:text-left"
          h4Class="h4-class"
          h1Class="h1-class w-96"
          paragraphClass="p-class w-10/12"
          h4="2022"
          h1={language[1].titre22B}
          p={language[1].descritpion22}
        />
      </div>
      <div className="lg:grow lg:h-full lg:pr-40 flex justify-center items-center z-20">
        <video
          className="w-full lg:w-9/12 lg:mb-14 mt-10 lg:mt-0"
          autoPlay
          muted
        >
          <source src="videos/ending.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  );
};

export default Third;
