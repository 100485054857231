import { createSlice, current } from "@reduxjs/toolkit";
import { useState } from "react";

const initialState = {
  language: [
    {
      en: false,
      fr: true,
      de: false,
      es: false,
    },
    {
      messageMobile: "Désolé, le site des 30 ans n'est disponible que sur ordinateur.",
      backButton: "Retourner sur le site",
      logoWhite: "/images/logos/logo-white_FR.png",
      logoBlack: "/images/logos/logo-black_FR.png",
      scrollText: "Scroll down et découvrez notre histoire",
      product: "Products",
      galerie: "Galerie",
      entreprise: "Entrepise",
      news: "News",
      partners: "Partners",
      downloads: "Downloads",
      blackH1: "",
      title1994:
        "La marque Vitrocsa est déposée ainsi qu'un premier brevet pour le système coulissant",
      text1994: "D'où vient le nom VITROCSA ?",
      subtext1994: "Un simple mot qui résume l'origine du produit.",
      title1996:
        "Un nouveau design est imaginé pour les fenêtres Vitrocsa 3001",
      text1996:
        "Qui va être adopté l’année 2000 pour des multiples agrandissements et constructions de villas",
      title2001: "La pivotante vitrocsa 3001 est créé",
      title2002: "Le système de guillotine est développé",
      title2007: "Lancement de la gamme TH+",
      text2007: "Qui offre une excellente qualité thermique ",
      title2010: "Lancement de la gamme Vitrocsa Swimms",
      text2010: "Conçue pour répondre aux besoins des pays chaud",
      title2011:
        "Développement du seuil invisible à deux pistes isolé thermiquement",
      title2012:
        "Vitrocsa développe le système unique et révolutionnaire du Turnable corner et dépose un nouveau brevet         ",
      text2012: "L’application de coulissante cintrée voit le jour",
    },
  ],
};

const languageSlice = createSlice({
  name: "Language",
  initialState: initialState,
  reducers: {
    changeLanguage(state, action) {
      state.language[0].en = action.payload.en;
      state.language[0].fr = action.payload.fr;
      state.language[0].de = action.payload.de;
      state.language[0].es = action.payload.es;

      const english = state.language[0].en;
      const french = state.language[0].fr;
      const german = state.language[0].de;
      const spanish = state.language[0].es;

      const text = state.language[1];

      if (english) {
        text.messageMobile = "Sorry, the 30 years site is only available on computer.";
        text.backButton = "Return to the website";
        text.logoWhite = "/images/logos/logo-white_EN.png";
        text.logoBlack = "/images/logos/logo-black_EN.png";
        text.scrollText = "Scroll down and discover our story";
        text.product = "Products";
        text.galerie = "Gallery";
        text.entreprise = "Company";
        text.news = "News";
        text.partners = "Partners";
        text.downloads = "Downloads";
        text.vitrocsaInnovation = "30 years of innovation";
        text.blackH1 =
          "Éric Joray created the company Orchidées Constructions SA";
        text.blackTextL1 =
          "Éric Joray began designing windows by combining his passions for orchids and architecture.";
        text.blackTextL2 =
          "What is the link between these two worlds? Where do you grow orchids? In greenhouses and conservatories, made of... glazed panels.";
        text.blackTextL3 = "And so the adventure began!";
        text.titre92 = "Éric Joray invented the modern minimalist window";
        text.titre21 =
          "The invisible frame with one insulated track was launched";
        text.descritpion21 = `That same year, this innovation won three prizes at the "Architizer A+Products Awards", in the "Residential", "Doors and Hardware" and "Commercial" categories`;
        text.titre22 = "The motorised Turnable Corner was developed";
        text.titre22B = "The original product celebrated its 30th anniversary";
        text.descritpion22 =
          "Thanks to its extensive experience, the brand is more focussed on the future than ever before, and will continue to revolutionise the world of the minimalist window.";
        text.titre14 = "The V32 range was launched, with thermal reinforcement";
        text.titre20 = "The motorised curved application was developed";
        text.titre15 = "Éric Joray gradually passed the baton to his team";
        text.titre16 =
          "Vitrocsa became the owner of a watchmaking building located in Saint-Aubin-Sauges, employing about twenty staff.";
        text.description16 =
          "Worldwide, the brand's partners selling and installing the range of products exceeded 500 people.";
        text.titre17 =
          "The V56 range was launched, allowing infinite glazing surfaces";
        text.descriptionMap1 =
          "The European market opened up with the stadium of Braga in Portugal for the UEFA European football championship in 2004";
        text.titre0406 = "Between 2004 and 2006";
        text.description0406 =
          "new markets opened up: Israel, Australia, Russia, India, Canada and the USA";
        text.titre0810 = "Between 2008 and 2010";
        text.description0810 =
          "Vitrocsa opened up to the markets in Lebanon and the United Arab Emirates";
        text.titre13 = "South Korea and South Africa were added as new markets";
        text.titre21B =
          "A test bench was purchased to test elements in excess of 30 m2";
        text.titre19 = "Vitrocsa acquired two new machines";
        text.description19 =
          "A Tornos bar-turner and a machine for crimping profiles and thermal breaks";
        text.footerThank = "thank you";
        text.title1994 =
          "The Vitrocsa trademark was registered along with the first patent for the sliding system";
        text.text1994 = "Where does the name VITROCSA come from?";
        text.subtext1994 =
          "A simple word which sums up the origin of the product.";
        text.title1996 = "A new design was created for Vitrocsa 3001 WINDOWS";
        text.text1996 =
          "Multiple extensions of homes and new builds were designed around Vitrocsa 3001 windows";
        text.title2001 = "The pivoting Vitrocsa 3001 was created";
        text.title2002 = "The guillotine system was developed";
        text.title2007 = "The TH+ range was launched";
        text.text2007 = "Offering excellent insulating properties";
        text.title2010 = "The Vitrocsa Swimms range was launched";
        text.text2010 = "Designed to meet the needs of hot countries";
        text.title2011 =
          "The invisible frame with two insulated tracks was developed, and a patent was filed";
        text.title2012 =
          "Vitrocsa developed the unique and revolutionary Turnable Corner system and filed a new patent ";
        text.text2012 = "The curved sliding application was developed";
      }
      if (french) {
        text.messageMobile = "Désolé, le site des 30 ans n'est disponible que sur ordinateur.";
        text.backButton = "Retourner sur le site";
        text.logoWhite = "/images/logos/logo-white_FR.png";
        text.logoBlack = "/images/logos/logo-black_FR.png";
        text.scrollText = "Scroll down et découvrez notre histoire";
        text.product = "Produits";
        text.galerie = "Galerie";
        text.entreprise = "Entreprise";
        text.news = "Actualités";
        text.partners = "Partenaires";
        text.downloads = "Téléchargement";
        text.vitrocsaInnovation = "30 ans d'innovation";
        text.blackH1 = "Éric Joray crée la société Orchidées Constructions SA";
        text.blackTextL1 =
          "Éric Joray se lança dans la conception de baies vitrées en alliant ses passions pour les orchidées et l'architecture.";
        text.blackTextL2 =
          "Quel est le lien entre ces deux univers ? Où fait-on pousser les orchidées ? Dans des serres et des jardins d'hiver, composé de... baie vitrées.";
        text.blackTextL3 = "L'aventure est lancée !";
        text.titre92 = "Éric Joray invente la fenêtre minimale moderne";
        text.titre21 =
          "Lancement du seuil invisible une piste, isolé thermiquement";
        text.descritpion21 = `L'innovation remporta la même année trois Awards aux "Architizer A+Products Awards". Dans les catégories : "Residential", "Doors and Hardware" et "Commercial"`;
        text.titre22 = "Développement du Turnable Corner motorisé";
        text.titre22B = "Le produit original célèbre ses 30 ans";
        text.descritpion22 =
          "Riche de son expérience, la marque est plus que jamais tournée vers l'avenir et compte continuer à révolutionner le monde de la fenêtre minimale.";
        text.titre14 = "Lancement de la gamme V32 thermiquement renforcée";
        text.titre20 = "Développement de l’application cintrée motorisée";
        text.titre15 =
          "Éric Joray passe progressivement le relais à son équipe";
        text.titre16 =
          "Vitrocsa devient propriétaire d'un bâtiment horloger situé à Saint-Aubin-Sauges et emploie une vingtaine de personnes.";
        text.description16 =
          "Dans le monde, la marque représente plus de 500 personnes qui vendent et installent les divers produits";
        text.titre17 =
          "Lancement de la gamme V56 qui permet des surfaces de vitrage infinies";
        text.descriptionMap1 =
          "Le marché européen s'ouvre avec le stade de Braga au Portugal à l'occasion de l'euro de football 2004";
        text.titre0406 = "De 2004 à 2006";
        text.description0406 =
          "de nouveaux marchés s'ouvrent : Israël, Australie, Russie, Inde, Canada et Etats-Unis";
        text.titre0810 = "Entre 2008 et 2010";
        text.description0810 =
          "Vitrocsa s'ouvre aux marchés du Liban et des Émirats Arabes Unis";
        text.titre13 =
          "La Corée du Sud et l’Afrique du Sud deviennent de nouveaux marchés";
        text.titre21B = "Acquisition d'un banc d'essais";
        text.titre19 = "Vitrocsa fait l’acquisition de deux nouvelles machines";
        text.description19 =
          "Une décolleteuse tornos et une machine à sertir les profils et à les isoler thermiquement ";
        text.footerThank = "Merci";
        text.title1994 =
          "La marque Vitrocsa est déposée ainsi qu'un premier brevet pour le système coulissant";
        text.text1994 = "D'où vient le nom VITROCSA ?";
        text.subtext1994 = "Un simple mot qui résume l'origine du produit.";
        text.title1996 =
          "Un nouveau design est imaginé pour les fenêtres Vitrocsa 3001";
        text.text1996 =
          "Qui va être adopté l’année 2000 pour des multiples agrandissements et constructions de villas";
        text.title2001 = "La pivotante vitrocsa 3001 est créé";
        text.title2002 = "Le système de guillotine est développé";
        text.title2007 = "Lancement de la gamme TH+";
        text.text2007 = "Qui offre une excellente qualité thermique ";
        text.title2010 = "Lancement de la gamme Vitrocsa Swimms";
        text.text2010 = "Conçue pour répondre aux besoins des pays chaud";
        text.title2011 =
          "Développement du seuil invisible à deux pistes isolé thermiquement";
        text.title2012 =
          "Vitrocsa développe le système unique et révolutionnaire du Turnable corner et dépose un nouveau brevet         ";
        text.text2012 = "L’application de coulissante cintrée voit le jour";
      }
      if (german) {
        text.messageMobile = "Leider ist die 30-Jahre-Website nur auf dem Computer verfügbar.";
        text.backButton = "Zurück zur Website";
        text.logoWhite = "/images/logos/logo-white_DE.png";
        text.logoBlack = "/images/logos/logo-black_DE.png";
        text.scrollText =
          "Scrollen Sie nach unten und entdecken Sie unsere Geschichte";
        text.product = "Produkte";
        text.galerie = "Galerie";
        text.entreprise = "Unternehmen";
        text.news = "Neuigkeiten";
        text.partners = "Partnern";
        text.downloads = "Downloads";
        text.vitrocsaInnovation = "30 Jahre Innovation";
        text.blackH1 =
          "Éric Joray gründet die Firma Orchidées Constructions SA";
        text.blackTextL1 =
          "Éric Joray beschäftigt sich mit der Planung von Panoramafenstern und verbindet seine Liebe zu Orchideen mit seiner Leidenschaft für Architektur.";
        text.blackTextL2 =
          "Was verbindet diese beiden Universen? Wo gedeihen Orchideen? In Gewächshäusern und Wintergärten mit ... großen Fenstern.";
        text.blackTextL3 = "Das Abenteuer beginnt!";
        text.titre92 = "Eric Joray erfindet das moderne Minimalfenster";
        text.titre21 =
          "Markteinführung des schwellenlosen Bodenübergangs mit einer thermisch isolierten Laufbahn";
        text.descritpion21 = `Die Innovation wird noch im selben Jahr mit drei Architizer „A+Product Awards“ in den Kategorien „Residential“, „Doors and Hardware“ und „Commercial“ ausgezeichnet.`;
        text.titre22 = "Entwicklung des motorisierten Turnable-Corner-Systems";
        text.titre22B = "Das Originalprodukt feiert sein 30-Jahr-Jubliäum";
        text.descritpion22 =
          "Gestärkt durch ihren Erfahrungsschatz hat sich die Marke mehr denn je der Zukunft verschrieben und wird die Welt der Minimalfenster weiterhin revolutionieren.";
        text.titre14 =
          "Markteinführung der Produktreihe V32 mit thermischer Verstärkung";
        text.titre20 = "Entwicklung der motorgetriebenen gewölbten Anwendung";
        text.titre15 =
          "Éric Joray übergibt nach und nach den Stab an sein Team";
        text.titre16 =
          "Vitrocsa wird Inhaber eines Uhrenfabrikgebäudes in Saint-Aubin-Sauges und beschäftigt rund 20 Mitarbeitende.";
        text.description16 =
          "Die Marke zählt weltweit über 500 Fachkräfte für den Verkauf und die Installation der verschiedenen Produkte.";
        text.titre17 =
          "Markteinführung der Produktreihe V56, die unbegrenzte Glasflächen bietet";
        text.descriptionMap1 =
          "Mit dem Bau des Fußballstadions im portugiesischen Braga anlässlich der Euro 2004 öffnet sich der gesamteuropäische Markt";
        text.titre0406 = "Zwischen 2004 und 2006";
        text.description0406 =
          "werden neue Märkte erschlossen: Israel, Australien, Russland, Indien, Kanada und die USA";
        text.titre0810 = "Zwischen 2008 und 2010";
        text.description0810 =
          "öffnet sich Vitrocsa gegenüber den Märkten im Libanon und den Vereinigten Arabischen Emiraten";
        text.titre13 = "Südkorea und Südafrika werden zu neuen Märkten";
        text.titre21B =
          "Erwerb eines Prüfstands, mit dem über 30 m² große Glaselemente getestet werden können";
        text.titre19 = "Vitrocsa erwirbt zwei Maschinen";
        text.description19 =
          "Einen Tornos-Drehautomaten und eine Maschine zum Einpressen von Profilen und thermischen Trennungen";
        text.footerThank = "Danke";
        text.title1994 =
          "Eintragung der Marke Vitrocsa sowie des ersten Patents für ein Schiebefenstersystem";
        text.text1994 = "Woher kommt der Name VITROCSA? ";
        text.subtext1994 =
          "Ein einfaches Wort, das die Herkunft des Produkts enthält.";
        text.title1996 =
          "Für das Modell Vitrocsa 3001 wird ein neues Fensterdesign entworfen";
        text.text1996 =
          "Realisierung mehrerer Anbauten und Villen mit Fenstern der Produktreihe Vitrocsa 3001";
        text.title2001 = "Das Schwenkfenster Vitrocsa 3001 wird entwickelt";
        text.title2002 = "Das Senkrechtschiebefenster wird entwickelt";
        text.title2007 = "Markteinführung der Produktreihe TH+";
        text.text2007 = "Mit hervorragenden thermischen Eigenschaften";
        text.title2010 = "Markteinführung der Produktreihe Vitrocsa Swimms";
        text.text2010 = "Für die Bedürfnisse von Ländern mit warmem Klima";
        text.title2011 =
          "Entwicklung des schwellenlosen Bodenübergangs mit zwei wärmeisolierten Laufbahnen und Patentanmeldung";
        text.title2012 =
          "Vitrocsa entwickelt das einzigartige, revolutionäre Turnable-Corner-System und meldet ein neues Patent an ";
        text.text2012 = "Das gewölbte Schiebeglaselement wird entwickelt";
      }
      if (spanish) {
        text.messageMobile = "Lo sentimos, el sitio de los 30 años sólo está disponible en ordenador.";
        text.backButton = "Volver al sitio";
        text.logoWhite = "/images/logos/logo-white_ES.png";
        text.logoBlack = "/images/logos/logo-black_ES.png";
        text.scrollText = "Desplácese hacia abajo y descubra nuestra historia";
        text.product = "Productos";
        text.galerie = "Galería";
        text.entreprise = "Empresa";
        text.news = "Actualidades";
        text.partners = "Distribuidor";
        text.downloads = "Descarga";
        text.vitrocsaInnovation = "30 años de innovación";
        text.blackH1 = "Éric Joray funda la empresa Orchidées Constructions SA";
        text.blackTextL1 =
          "Éric Joray comienza a diseñar superficies acristaladas combinando sus dos pasiones: las orquídeas y la arquitectura.";
        text.blackTextL2 =
          "¿Cuál es el nexo de unión entre ambos universos? ¿Dónde se cultivan las orquídeas? En invernaderos y terrazas cerradas, estructuras formadas por superficies acristaladas.";
        text.blackTextL3 = "¡Empieza la aventura!";
        text.titre92 = "Éric Joray inventa la ventana minimalista moderna";
        text.titre21 =
          "Tiene lugar el lanzamiento de la guía invisible de una pista con aislamiento térmico";
        text.descritpion21 = `Ese mismo año, la marca consigue tres premios «Architizer A+Products Awards», en reconocimiento a la innovación en las categorías «Residential», «Doors and Hardware» y «Commercial»`;
        text.titre22 =
          "Se completa el desarrollo del sistema Turnable Corner motorizado";
        text.titre22B = "El producto original celebra su 30.º aniversario";
        text.descritpion22 =
          "Gracias a la experiencia que atesora, la empresa está mejor preparada que nunca para adoptar un enfoque con visión de futuro y pretende seguir revolucionando el mundo de la ventana minimalista.";
        text.titre14 =
          "Tiene lugar el lanzamiento de la gama Vitrocsa V32 con refuerzo térmico";
        text.titre20 =
          "Se avanza en el desarrollo de la aplicación curvada motorizada";
        text.titre15 = "Éric Joray cede progresivamente el relevo a su equipo";
        text.titre16 =
          "Vitrocsa se convierte en propietaria de un edificio del sector relojero ubicado en Saint-Aubin-Sauges (Suiza) y contrata a una veintena de personas.";
        text.description16 =
          "La marca cuenta con más de 500 empleados en todo el mundo que venden e instalan los diferentes productos.";
        text.titre17 =
          "Se produce el lanzamiento de la gama V56, que permite crear superficies acristaladas infinitas";
        text.descriptionMap1 =
          "Tiene lugar la apertura al mercado europeo con el Estadio de Braga (Portugal) con motivo de la UEFA Euro 2004";
        text.titre0406 = "De 2004 a 2006";
        text.description0406 =
          "se lleva a cabo la ampliación a nuevos mercados: Israel, Australia, Rusia, India, Canadá y Estados Unidos";
        text.titre0810 = "Entre 2008 y 2010";
        text.description0810 =
          "Vitrocsa se introduce en los mercados del Líbano y los Emiratos Árabes Unidos";
        text.titre13 =
          "Corea del Sur y África del Sur se convierten en nuevos mercados para la marca";
        text.titre21B =
          "Vitrocsa adquiere un banco de pruebas para comprobar elementos de más de 30 m2";
        text.titre19 = "Vitrocsa adquiere dos nuevas máquinas";
        text.description19 =
          "Una máquina de torneado Tornos, así como una máquina para engarzar perfiles y roturas térmicas";
        text.footerThank = "gracias";
        text.title1994 =
          "Registro de la marca Vitrocsa y de la primera patente para el sistema de corredera";
        text.text1994 = "¿De dónde viene el nombre VITROCSA?  ";
        text.subtext1994 =
          "Un nombre sencillo que resume el origen del producto.";
        text.title1996 =
          "Se crea un nuevo diseño para las ventanas Vitrocsa 3001";
        text.text1996 =
          "Se utilizan ventanas Vitrocsa 3001 en numerosas ampliaciones y construcciones de viviendas";
        text.title2001 = "Se crea la pivotante Vitrocsa 3001";
        text.title2002 = "Se desarrolla el sistema de guillotina";
        text.title2007 = "Se lanza la gama Vitrocsa TH+";
        text.text2007 = "Que ofrece una excelente calidad térmica";
        text.title2010 = "Se lanza la gama Vitrocsa Swimms";
        text.text2010 =
          "Diseñada para satisfacer las necesidades de los países más cálidos";
        text.title2011 =
          "La empresa desarrolla la guía invisible de dos pistas con aislamiento térmico y registro de una patente";
        text.title2012 =
          "Vitrocsa desarrolla Turnable Corner, un sistema único y revolucionario, y registra una nueva patente ";
        text.text2012 = "Surge la aplicación de corredera curvada";
      }
    },
  },
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
