import React from "react";
import { useSelector } from "react-redux";

import Text from "./Text";
import Ellipse from "../../Reusable/Ellipse";

const BlackSection = () => {
  const language = useSelector((state) => state.language.language);

  return (
    <>
      <div className="h-[300vh] lg:h-[100vh] black-page bg-black w-full flex lg:flex-row flex-col lg:justify-center items-center">
        <section className="flex flex-col w-full lg:w-2/5 justify-center z-10 gap-5 lg:gap-10 section-1 mt-10">
          <Text
            textClass="text-gold self-left lg:text-left text-center 2xl:text-4xl xl:text-3xl text-2xl"
            text="1989
          "
          />
          <Text
            textClass="text-6xl 2xl:text-6.5xl 2xl:w-full xl:text-5xl xl:w-3/4 lg:text-4xl md:text-3xl lg:text-left text-3.5xl text-center uppercase text-white"
            text={language[1].blackH1}
          />
          <div className="flex flex-col items-center lg:items-start gap-4 2xl:w-10/12 2xl:text-lg xl:text-base xl:w-7/12 lg:text-sm lg:w-6/12 text-white lg:text-left text-center">
            <Text
              textClass="w-10/12 lg:w-full"
              text={language[1].blackTextL1}
            />
            <Text
              textClass="w-10/12 lg:w-full"
              text={language[1].blackTextL2}
            />
          </div>
        </section>
        <section className="flex section-2 z-10 lg:mt-48 lg:mr-56 xl:mr-20">
          <img
            src="/images/1989/1989-1.png"
            alt=""
            className="my-36 h-[225px] section-2-image-1"
          />
          <img
            src="/images/1989/1989-2.png"
            alt=""
            className="my-60 h-[412px] section-2-image-2"
          />
        </section>
        <section className="section-3 lg:absolute right-1/4 w-full lg:w-2/5 h-[100vh] flex justify-center items-center lg:translate-x-[120%] lg:translate-y-80">
          <div className="py-5 px-5 h-[65vh] lg:h-3/4 w-11/12 lg:w-3/4 lg:min-w-[30rem] gold-border border-2 border-gold">
            <Text
              textClass="text-gold py-5 lg:py-10 lg:px-10 text-center lg:text-left text-2xl lg:text-4xl"
              text="1992
            "
            />
            <Text
              textClass="text-3.25xl lg:text-4xl w-full  lg:w-3/4 lg:ml-10 text-center lg:text-left uppercase text-white"
              text={language[1].titre92}
            />
          </div>
        </section>
        <div className="relative lg:absolute bottom-80 gap-24 h-[75vh] lg:gap-0 section-3-img-container lg:bottom-10 lg:left-1/5 flex flex-col lg:w-10/12 2xl:w-10/12 lg:h-4/5 overflow-hidden items-end lg:justify-end">
          <img
            src="/images/1992/1992 2.png"
            alt=""
            className="lg:w-1/4 lg:h-1/4 section-3-image-1 lg:mr-24"
          />
          <img
            src="/images/1992/1994 2.png"
            alt=""
            className="w-full h-full section-3-image-2 lg:absolute"
          />
        </div>
        <Ellipse className={"bg-white ellipse-2 z-30"} />
      </div>
    </>
  );
};
export default BlackSection;
