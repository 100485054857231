import React from "react";
import { useSelector } from "react-redux";

// Sections
import First from "./First";
import Second from "./Second";
import Third from "./Third";

const Footer = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <footer className="gap-40 mt-20 lg:mt-0 flex items-start flex-col w-full lg:translate-y-[30vw]">
      <First />
      <div className="footer-second mt-20 lg:mt-0 lg:min-h-screen w-full">
        <Second />
        <Third />
      </div>
      <div className="w-full thankyou h-[50vh] lg:h-[100vh] pt-10 lg:mt-0 flex items-center lg:items-start justify-center gap-10">
        <div className="thankyou-div-left before:lg:flex w-1/12 lg:translate-x-[9.25rem] lg:w-5/12 h-24 bg-white z-30 border-r border-gray-600" />
        <h1 className="font-helvetica lg:pt-7 text-2xl lg:text-4xl font-black uppercase">
          {language[1].footerThank}
        </h1>
        <div className="thankyou-div-right lg:flex w-1/12 lg:-translate-x-[9.25rem] lg:w-5/12 h-24 bg-white z-30 border-l border-gray-600" />
      </div>
    </footer>
  );
};

export default Footer;
