import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// Pages

import Home from "./Components/Home/Home";
import Gallery from "./Components/Gallery/Gallery";
import MobileWarning from "./Components/MobileWarning";

// Animations

import Animations from "./Animations/Animations";
import Loader from "./Animations/Loader";
import Transition from "./Animations/Transition";

function App() {
  const click = useSelector((state) => state.animate.transition);
  const [show, setShow] = useState();
  const [showGallery, setShowGallery] = useState();
  const responsive = window.matchMedia("(min-width: 1024px)");
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1280) setShowMobileWarning(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow(click);
    }, 1350);
  }, [click]);

  useEffect(() => {
    window.history.replaceState(null, "Vitrocsa", "/en");
  }, []);

  const showHandler = (e) => {
    setShowGallery(e);
    document.querySelector("body").style.overflow = "hidden";
    setTimeout(() => {
      document.querySelector("body").style.overflow = "auto";
    }, 7000);
  };

  return (
    <div className="app overflow-hidden w-[100vw]">
      <Transition />
      {responsive.matches && <Animations />}
      <BrowserRouter>
        <Routes>
          <Route>
            <Route
              path="/"
              element={
                <>
                  <Loader show={showHandler} />
                  {showGallery && <Gallery language="en" />}
                </>
              }
            />
            <Route
              path="es"
              element={
                showMobileWarning ? (
                  <MobileWarning languageMobile="es" />
                ) : (
                  <>
                    <Loader show={showHandler} />
                    {showGallery && <Gallery language="es" />}
                  </>
                )
              }
            />
            <Route
              path="en"
              element={
                showMobileWarning ? (
                  <MobileWarning languageMobile="en" />
                ) : (
                  <>
                    <Loader show={showHandler} />

                    {showGallery && <Gallery language="en" />}
                  </>
                )
              }
            />
            <Route
              path="fr"
              element={
                showMobileWarning ? (
                  <MobileWarning languageMobile="fr" />
                ) : (
                  <>
                    <Loader show={showHandler} />

                    {showGallery && <Gallery language="fr" />}
                  </>
                )
              }
            />
            <Route
              path="de"
              element={
                showMobileWarning ? (
                  <MobileWarning languageMobile="de" />
                ) : (
                  <>
                    <Loader show={showHandler} />

                    {showGallery && <Gallery language="de" />}
                  </>
                )
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
