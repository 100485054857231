import { configureStore } from "@reduxjs/toolkit";
import animateReducer from "./animate";
import languageReducer from "./language";

const store = configureStore({
  reducer: {
    animate: animateReducer,
    language: languageReducer,
  },
});

export default store;
