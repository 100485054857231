import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { animateTransition } from "../Redux/store/animate";
import { changeLanguage } from "../Redux/store/language";
import DelayedLink from "./DelayedLink";
import { gsap } from "gsap";
import { useSelector } from "react-redux";

const Navbar = ({
  burgerClass,
  logoWhite,
  logoBlack,
  navbarText,
  navClass,
  ansClass,
  navbarElements,
}) => {
  const burger = useRef();
  const dispatch = useDispatch();
  const responsive = window.matchMedia("(min-width: 1024px)");
  const language = useSelector((state) => state.language.language);

  return (
    <nav
      className={`flex flex-col gap-4 w-full justify-between ${navClass} items-center lg:sticky top-0 z-40`}
    >
      <div
        className={`flex flex-row justify-between items-center w-full pl-24`}
      >
        <a href="https://vitrocsa.com/" className={`flex items-center`}>
          <img
            src="/images/arrow.png"
            alt="arrow directed to left"
            className="pr-4 h-4"
          />
          <p className={`text-[#9D8E68] text-sm`}>{language[1].backButton}</p>
        </a>

        <ul className="h-10 flex justify-end items-center pr-5">
          <li
            onClick={() => {
              dispatch(
                changeLanguage({ en: false, fr: false, de: true, es: false })
              );
              window.history.replaceState(null, "Vitrocsa", "/de");
            }}
            className="flex items-center language-text justify-center border-black w-14 h-10 font-sans cursor-pointer border-l"
          >
            DE
          </li>
          <li
            onClick={() => {
              dispatch(
                changeLanguage({ en: true, fr: false, de: false, es: false })
              );
              window.history.replaceState(null, "Vitrocsa", "/en");
            }}
            className="flex items-center language-text justify-center border-black w-14 h-10 font-sans cursor-pointer border-l"
          >
            EN
          </li>
          <li
            onClick={() => {
              dispatch(
                changeLanguage({ en: false, fr: true, de: false, es: false })
              );
              window.history.replaceState(null, "Vitrocsa", "/fr");
            }}
            className="flex items-center language-text justify-center border-black w-14 h-10 font-sans cursor-pointer border-l"
          >
            FR
          </li>
          <li
            onClick={() => {
              dispatch(
                changeLanguage({ en: false, fr: false, der: false, es: true })
              );
              window.history.replaceState(null, "Vitrocsa", "/es");
            }}
            className="flex items-center language-text justify-center border-black w-14 h-10 font-sans cursor-pointer border-l"
          >
            ES
          </li>
        </ul>
      </div>
      <ul
        className={`flex w-full ${navClass} justify-between items-center px-7 lg:px-24`}
      >
        <img
          className={`2xl:w-1/4 lg:w-60 w-48 ${logoBlack}`}
          src={language[1].logoBlack}
          alt="Logo Vitrocsa"
        />
        <img
          className={`2xl:w-1/4 hidden lg:w-60 w-48 ${logoWhite}`}
          src={language[1].logoWhite}
          alt="Logo Vitrocsa"
        />

        <ul className="flex justify-center items-center">
          <DelayedLink
            delay={1350}
            to=""
            name={
              <img
                className={`xl:w-16 xl:h-16 lg:min-w-[2.5rem] lg:w-full lg:h-full h-10 w-10 cursor-pointer ${ansClass}`}
                src="/images/logos/30ans.png"
                alt="Logo des 30 ans"
              />
            }
          ></DelayedLink>
        </ul>
      </ul>
    </nav>
  );
};

export default Navbar;
