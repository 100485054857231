import React from "react";
import Section from "./Section";
import Text from "../../Reusable/Text";

const Slider = ({
  image,
  p,
  h4,
  h1,
  h1Class,
  h4Class,
  paragraphClass,
  paragraphDivClass,
  mainClass,
  leftDiv,
  video = false,
}) => {
  return (
    <Section
      mainClass={mainClass}
      leftClass="flex justify-center items-center"
      rightClass="flex h-full justify-center items-center"
      right={
        <>
          <div className="lg:w-full lg:h-[30vw] lg:overflow-hidden ">
            <div className="lg:h-[30vw] lg:w-[50vw] slide lg:overflow-hidden flex justify-center items-center ">
              {!video ? (
                <img
                  className="h-full w-full max-w-none image"
                  src={`/images/${image}`}
                />
              ) : (
                <video
                  className="h-full w-full max-w-none image"
                  autoPlay
                  muted
                >
                  <source src="videos/vitrocsa-2.mp4" type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </>
      }
      left={
        <>
          <>
            <div
              className={` h-4/6 w-5/6 flex flex-col items-center ${leftDiv}`}
            >
              <Text
                h4DivClass={`h-8 ${h4Class}`}
                h1DivClass={`mt-5 mb-5 ${h1Class}`}
                paragraphDivClass={`lg:w-72 ${paragraphClass}`}
                h4Class="h4-class"
                h1Class="h1-class"
                paragraphClass="p-class"
                h4={h4}
                h1={h1}
                p={p}
              />
            </div>
          </>
        </>
      }
    />
  );
};

export default Slider;
