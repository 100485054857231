import React from "react";

const Ellipse = ({ className }) => {
  return (
    <div
      className={`w-[30vw] h-[30vw] ${className} fixed bottom-[-16vw] left-[35%] rounded-full translate-y-full z-50`}
    ></div>
  );
};

export default Ellipse;
