import React from "react";
import { useSelector } from "react-redux";

const Scene = () => {
  const language = useSelector((state) => state.language.language);
  const scene1 = [
    {
      src: "/images/scene1/1.png",
      alt: "un",
      className: "h-[50vh] lg:h-[35vw]",
    },
    {
      src: "/images/scene1/2.png",
      alt: "deux",
      className: "h-[35vh] lg:h-[23vw]",
    },
    {
      src: "/images/scene1/3.png",
      alt: "trois",
      className: "h-[17vw] mb-[20vw] hidden lg:inline",
    },
    {
      src: "/images/scene1/4.png",
      alt: "quatre",
      className: "h-[30vw] hidden lg:inline",
    },
    {
      src: "/images/scene1/5.png",
      alt: "cinq",
      className: "h-[20vw] hidden lg:inline",
    },
  ];

  return (
    <section className="min-h-[80vh] my-10 z-40 bg-white">
      <div className="flex gap-6 lg:gap-0 lg:justify-between items-center">
        {scene1.map((item) => {
          return (
            <img
              key={Math.random()}
              src={item.src}
              alt={item.alt}
              className={item.className}
            />
          );
        })}
      </div>
      <div className="flex mt-12 lg:mt-0 flex-col items-center relative gap-6 lg:gap-0">
        <div className="w-40 text-center">{language[1].scrollText}</div>
        <svg
          viewBox="0 0 100 100"
          className="mouse w-28 h-28 hidden lg:block"
          version="1.1"
          x="0px"
          y="0px"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="#000000">
              <path d="M40,41.0074017 L40,41.0074017 L40,58.9925983 C40,64.5218355 44.4762336,69 50,69 C55.5234877,69 60,64.5203508 60,58.9925983 L60,41.0074017 C60,35.4781645 55.5237664,31 50,31 C44.4765123,31 40,35.4796492 40,41.0074017 L40,41.0074017 Z M38,41.0074017 C38,34.3758969 43.3711258,29 50,29 C56.627417,29 62,34.3726755 62,41.0074017 L62,58.9925983 C62,65.6241031 56.6288742,71 50,71 C43.372583,71 38,65.6273245 38,58.9925983 L38,41.0074017 L38,41.0074017 Z" />
              <path d="M49,36 L49,40 C49,40.5522847 49.4477153,41 50,41 C50.5522847,41 51,40.5522847 51,40 L51,36 C51,35.4477153 50.5522847,35 50,35 C49.4477153,35 49,35.4477153 49,36 L49,36 Z" />
              <path d="M50,81.9929939 L55.4998372,76.4931567 C55.8903615,76.1026324 56.5235265,76.1026324 56.9140508,76.4931567 C57.3045751,76.883681 57.3045751,77.516846 56.9140508,77.9073703 L50.7071068,84.1143143 C50.5118446,84.3095764 50.2559223,84.4072075 50,84.4072075 C49.7440777,84.4072075 49.4881554,84.3095764 49.2928932,84.1143143 L43.038379,77.8598002 C42.6478547,77.4692759 42.6478547,76.8361109 43.038379,76.4455866 C43.4289033,76.0550623 44.0620683,76.0550623 44.4525926,76.4455866 L50,81.9929939 Z" />
              <path d="M50.7071068,15.8856857 L56.9140508,22.0926297 C57.3045751,22.483154 57.3045751,23.116319 56.9140508,23.5068433 C56.5235265,23.8973676 55.8903615,23.8973676 55.4998372,23.5068433 L50,18.0070061 L44.4525926,23.5544134 C44.0620683,23.9449377 43.4289033,23.9449377 43.038379,23.5544134 C42.6478547,23.1638891 42.6478547,22.5307241 43.038379,22.1401998 L49.2928932,15.8856857 C49.4881554,15.6904236 49.7440777,15.5927925 50,15.5927925 C50.2559223,15.5927925 50.5118446,15.6904236 50.7071068,15.8856857 Z" />
            </g>
          </g>
        </svg>
        <svg
          className="lg:hidden"
          width="48"
          height="54"
          viewBox="0 0 48 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.3018 19.4571C42.1702 19.4571 41.1477 19.9124 40.4146 20.6415V18.706C40.4146 16.4841 38.6067 14.6762 36.3847 14.6762C35.2183 14.6762 34.1691 15.1773 33.4325 15.9719C33.0996 14.0829 31.4507 12.6416 29.4676 12.6416C28.3361 12.6416 27.3135 13.1119 26.5804 13.8659V4.97454C26.5804 2.75256 24.7725 0.944702 22.5506 0.944702C20.3286 0.944702 18.5207 2.75256 18.5207 4.97454V25.7646C14.6927 25.7722 11.8066 29.3838 11.8066 34.1723V35.0986C11.8066 39.6255 13.0873 47.8058 21.6637 51.7307C23.8219 52.7178 26.1953 52.8657 28.4903 53.0078C29.3377 53.06 30.2518 53.1006 31.2082 53.1006C33.8664 53.1006 36.8487 52.7921 39.6176 51.588C44.514 49.4594 47.3972 44.4876 47.3305 38.2938V23.3553C47.3316 21.2058 45.5244 19.4571 43.3018 19.4571ZM46.1885 38.299C46.2511 44.11 43.6898 48.5708 39.1629 50.5388C35.6209 52.0787 31.6368 52.0584 28.5611 51.8664C26.3704 51.7307 24.1044 51.5903 22.1399 50.6907C14.1446 47.0315 12.9504 39.3506 12.9504 35.0974V34.1712C12.9504 30.0346 15.3441 26.9142 18.5213 26.9067V32.7125C18.5213 33.0286 18.7771 33.2838 19.0926 33.2838C19.4087 33.2838 19.6639 33.028 19.6639 32.7125V4.97396C19.6639 3.38186 20.959 2.08672 22.5506 2.08672C24.1427 2.08672 25.4378 3.38186 25.4378 4.97396V16.6714V25.7246C25.4378 26.0407 25.6936 26.2959 26.0091 26.2959C26.3246 26.2959 26.5804 26.0401 26.5804 25.7246V16.6714C26.5804 15.0793 27.8755 13.7842 29.4676 13.7842C31.0597 13.7842 32.3549 15.0793 32.3549 16.6714V18.706V26.3348V26.8434C32.3549 27.1595 32.6107 27.4147 32.9262 27.4147C33.2417 27.4147 33.4975 27.159 33.4975 26.8434V26.3348V18.706C33.4975 17.1139 34.7926 15.8188 36.3847 15.8188C37.9768 15.8188 39.272 17.1139 39.272 18.706V23.3553V27.5632V27.8613C39.272 28.1774 39.5277 28.4326 39.8433 28.4326C40.1588 28.4326 40.4146 28.1769 40.4146 27.8613V27.5626V23.3547C40.4146 21.8351 41.7097 20.5997 43.3018 20.5997C44.8933 20.5997 46.1885 21.8357 46.1885 23.3547V38.299Z"
            fill="#0A0A0A"
          />
          <path
            d="M5.36578 41.3782V29.7747C5.36578 29.4586 5.11 29.2034 4.79448 29.2034C4.47896 29.2034 4.2226 29.4586 4.2226 29.7747V41.3782L1.69032 38.1296C1.49602 37.8808 1.13642 37.8367 0.887596 38.0304C0.638776 38.2247 0.595276 38.5844 0.788416 38.8332L4.34324 43.3926C4.3502 43.4013 4.36064 43.4047 4.36818 43.4128C4.40588 43.4563 4.45112 43.4894 4.49984 43.5196C4.51956 43.5318 4.5358 43.548 4.55668 43.5578C4.62976 43.5915 4.70864 43.613 4.79448 43.613C4.87974 43.613 4.9592 43.5915 5.0317 43.5584C5.05316 43.5486 5.0694 43.5318 5.08912 43.5196C5.13842 43.4888 5.18366 43.4558 5.22136 43.4123C5.22832 43.4042 5.23876 43.4013 5.24514 43.3931L8.80054 38.8338C8.88232 38.7294 8.92176 38.6052 8.92176 38.4828C8.92176 38.3123 8.84636 38.1441 8.70194 38.0316C8.45312 37.8373 8.09352 37.8814 7.8998 38.1302L5.36578 41.3782Z"
            fill="#0A0A0A"
          />
          <path
            d="M5.36578 14.7377V3.13418L7.89922 6.38276C8.09294 6.63158 8.45254 6.67566 8.70136 6.48136C8.84578 6.36942 8.92118 6.20064 8.92118 6.03012C8.92118 5.90716 8.88174 5.78362 8.79996 5.67922L5.24456 1.11984C5.13668 0.981225 4.9708 0.900024 4.79448 0.900024C4.61816 0.900024 4.45228 0.981805 4.34324 1.11984L0.788416 5.67922C0.595276 5.92862 0.638776 6.28822 0.887596 6.48194C1.13642 6.67566 1.49602 6.63158 1.69032 6.38276L4.2226 3.13418V14.7377C4.2226 15.0538 4.47838 15.309 4.7939 15.309C5.10942 15.309 5.36578 15.0532 5.36578 14.7377Z"
            fill="#0A0A0A"
          />
        </svg>
      </div>
    </section>
  );
};

export default Scene;
