import React from "react";
import Text from "../../Reusable/Text";
import { useSelector } from "react-redux";

const Second = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <section className="lg:h-96 footer-section-2 w-full z-50 flex flex-col-reverse lg:flex-row-reverse items-end justify-start gap-20">
      <img className="lg:w-80" src="/images/2022/2022.png" />
      <div>
        <Text
          h4DivClass={`lg:h-7 text-center lg:text-left`}
          h1DivClass={`lg:w-[20rem] text-3xl mt-4 text-center lg:text-left`}
          h4Class="h4-class"
          h1Class="h1-class"
          h4={"2022"}
          h1={language[1].titre22}
        />
      </div>
    </section>
  );
};

export default Second;
