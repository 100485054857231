import React from "react";
import Text from "../../Reusable/Text";
import { useSelector } from "react-redux";

const First = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <section className="footer-first lg:h-[100vh] flex flex-col lg:flex-row lg:min-h-screen lg:w-100vw">
      <div className="lg:w-6/12 h-3/6 w-full lg:min-h-screen lg:flex-row flex items-center justify-end lg:pl-[10rem]">
        <div className="lg:block h-0.5 lg:h-[0.12rem] w-12 lg:w-7 hidden absolute lg:relative -bottom-[6vw] left-[13px] lg:left-1 lg:bottom-[14.5rem] bg-gold"></div>
        <svg
          width="16"
          stroke="#9D8E68"
          height="800"
          viewBox="0 0 16 349"
          fill="none"
          className="absolute -z-10 lg:z-50 lg:relative h-[170vh] lg:h-[89.5vh] lg:top-60 left-[1vw] mt-[295vw] lg:mt-0 lg:-left-7 arrow"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.29289 348.207C7.68342 348.598 8.31658 348.598 8.70711 348.207L15.0711 341.843C15.4616 341.453 15.4616 340.819 15.0711 340.429C14.6805 340.038 14.0474 340.038 13.6569 340.429L8 346.086L2.34315 340.429C1.95262 340.038 1.31946 340.038 0.928932 340.429C0.538408 340.819 0.538408 341.453 0.928932 341.843L7.29289 348.207ZM7 -624L7 347.5L9 347.5L9 -624H7Z"
            fill="#9D8E68"
          />
        </svg>
        <div className="w-full">
          <Text
            h4DivClass="h-8 text-center lg:text-left mt-[1.4rem]"
            h1DivClass="lg:h-44 mb-5 lg:mb-0 lg:block flex justify-center lg:h-48 text-2.5xl text-center lg:text-left lg:w-[25rem] mt-5"
            paragraphDivClass="lg:w-[22rem] flex justify-center lg:block text-center lg:text-left"
            h4Class="h4-class"
            h1Class="h1-class w-96 lg:w-full"
            paragraphClass="p-class mt-6 text-lg lg:text-xl w-11/12 max-w-[25rem] lg:w-full"
            h4="2021"
            h1={language[1].titre21}
            p={language[1].descritpion21}
          />
          <p className="text-lg lg:text-xl lg:block w-44 lg:w-52 relative top-28">
            {language[1].titre21B}
          </p>
        </div>
      </div>
      <div className="lg:grow lg:h-full flex justify-center items-end">
        <img className="lg:w-8/12 mb-14" src="/images/2021/2021.png" />
        <img
          className="w-2/12 mb-[33rem] lg:ml-[40rem] hidden lg:block badge absolute"
          src="/images/2021/badge.png"
        />
      </div>
    </section>
  );
};

export default First;
