import React from "react";
import Text from "../../Reusable/Text";
import { useSelector } from "react-redux";

const Third = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <section className="horizontal-third lg:mb-0 gap-10 lg:gap-0 lg:absolute lg:flex-row flex flex-col lg:h-[100vh] w-100vw lg:translate-x-full lg:-translate-y-full">
      <div className="w-full lg:w-6/12 h-full lg:min-h-full flex items-center justify-center lg:justify-end lg:pr-32">
        <div className="w-11/12 lg:w-10/12 h-[110vh] lg:h-2/3 border-2 border-gold flex items-start lg:justify-center lg:items-center pt-10 lg:pt-20">
          <Text
            h4DivClass={`h-8 text-center lg:text-left`}
            h1DivClass={`lg:h-80 flex items-center justify-center lg:block text-center text-3xl lg:text-left lg:mb-8 lg:w-[27rem] mt-5`}
            paragraphDivClass={`h-40 lg:w-[20rem] lg:block flex items-center justify-center text-center text-lg text-center lg:text-left`}
            h4Class="h4-class"
            h1Class="h1-class w-11/12"
            paragraphClass="p-class w-80"
            h4={"2016"}
            h1={language[1].titre16}
            p={language[1].description16}
          />
        </div>
      </div>
      <div className="min-h-screen w-full lg:w-6/12 flex flex-col items-start justify-center">
        <div className="w-full h-80 flex lg:ml-52 lg:flex-row flex-col-reverse justify-end items-center gap-10">
          <img className="w-72 lg:w-52" src="/images/2017/2017.png" />
          <div className="">
            <Text
              h4DivClass={`h-8 text-center lg:text-left`}
              h1DivClass={`lg:h-28 text-center lg:text-left lg:w-[33rem] mt-5`}
              h4Class="h4-class"
              h1Class="h1-class text-3xl"
              h4={"2017"}
              h1={language[1].titre17}
            />
          </div>
        </div>
        <div className=" bottom-[90vh] lg:bottom-[1vh] flex justify-center items-center relative  lg:right-52 lg:w-2/5 lg:h-2/4 lg:justify-start">
          <img
            className="lg:w-full w-12/12 sm:w-8/12"
            src="/images/2016/2016.png"
          />
        </div>
      </div>
    </section>
  );
};

export default Third;
