import React from "react";
import Text from "../../Reusable/Text";
import { useSelector } from "react-redux";

const Fourth = () => {
  const language = useSelector((state) => state.language.language);
  return (
    <section className="horizontal-fourth flex flex-col lg:flex-row gap-10 lg:gap-0 lg:absolute lg:flex lg:h-[100vh] lg:w-[200vw] lg:translate-x-full lg:translate-y-2/4">
      <div className="lg:min-h-screen lg:w-6/12 flex flex-col-reverse lg:flex-row items-center justify-center gap-12">
        <img className="lg:w-1/6 h-3/5" src="/images/2019/2019.png" />
        <div>
          <Text
            h4DivClass={`h-7 text-center lg:text-left`}
            h1DivClass={`lg:h-20 text-3xl text-center lg:text-left lg:w-[30rem] mt-4`}
            paragraphDivClass={`lg:w-[25rem] mt-8 lg:mt-0 text-lg lg:text-2xl text-center lg:text-left`}
            h4Class="h4-class"
            h1Class="h1-class"
            paragraphClass="p-class"
            h4={"2019"}
            h1={language[1].titre19}
            p={language[1].description19}
          />
        </div>
      </div>
      <div className="lg:min-h-screen lg:w-6/12 flex-col-reverse lg:flex-row flex items-center justify-center gap-12 horizontal-fourth-section-2">
        <img className="lg:w-1/12 w-6/12 lg:h-52" src="/images/2020/2020.png" />
        <div className="lg:h-52 flex items-end">
          <Text
            h4DivClass={`h-7 text-center lg:text-left`}
            h1DivClass={` text-3xl text-center lg:text-left lg:w-[30rem] mt-4`}
            h4Class="h4-class"
            h1Class="h1-class"
            h4={"2020"}
            h1={language[1].titre20}
          />
        </div>
      </div>
    </section>
  );
};

export default Fourth;
