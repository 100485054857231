import React from "react";

// Sections
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";

const HorizontalSlider = () => {
  return (
    <main className="horizontal-page gap-10 lg:gap-0 w-full lg:w-[500vw] lg:h-[150vh] lg:-translate-y-[30vw] flex-col lg:flex-row flex">
      <First />
      <Second />
      <Third />
      <Fourth />
    </main>
  );
};

export default HorizontalSlider;
