import React from "react";
import Section from "../../Reusable/Section";
import Text from "../../Reusable/Text";

const Slider = ({
  image,
  p,
  h4,
  h1,
  h1Class,
  h4Class,
  paragraphClass,
  mainClass,
}) => {
  return (
    <Section
      mainClass={mainClass}
      leftClass="flex justify-center items-center h-2/6"
      rightClass="flex w-full h-3/6 lg:items-center lg:justify-center"
      right={
        <>
          <div className="lg:w-full h-[30vw] lg:overflow-hidden ">
            <div className="w-[100vw] lg:h-[30vw] lg:w-[50vw] slide lg:overflow-hidden flex justify-center items-center ">
              {/* <div className="h-[30vw] bg-black slide-black w-[60vw] z-50 absolute" /> */}
              <img
                className="h-full w-full max-w-none image"
                src={`/images/${image}`}
              />
            </div>
          </div>
        </>
      }
      left={
        <>
          <>
            <div className="w-full lg:w-5/6 flex flex-col justify-center items-center">
              <Text
                h4DivClass={`h-8 ${h4Class}`}
                h1DivClass={`h-28 mt-5 ${h1Class}`}
                paragraphDivClass={`h-16 w-72 ${paragraphClass}`}
                h4Class="h4-class"
                h1Class="h1-class"
                paragraphClass="p-class"
                h4={h4}
                h1={h1}
                p={p}
              />
            </div>
          </>
        </>
      }
    />
  );
};

export default Slider;
