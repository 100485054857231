import React from "react";

import { useSelector } from "react-redux";
import Ellipse from "../../Reusable/Ellipse";

const VitrocsaTitle = () => {
  const language = useSelector((state) => state.language.language);

  return (
    <div className="hidden lg:block vitrocsa-trigger min-h-screen">
      <section
        id="logo-vitrocsa"
        className="flex flex-col justify-center items-center h-[100vh] overflow-x-hidden"
      >
        <div id="volets" className="absolute">
          <div
            id="left"
            className="volet bg-white h-[65vh] border-r border-r-gold w-96 z-10 inline-block h-"
          >
            &nbsp;
          </div>
          <div
            id="right"
            className="volet bg-white h-[65vh] border-l border-l-gold w-96 z-10 inline-block"
          >
            &nbsp;
          </div>
        </div>
        <div className="flex text justify-around h-[65px] w-[800px]">
          <svg
            viewBox="0 0 53 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M46.652 0.983398H52.1405L29.8565 63.7136H22.284L0 0.983398H5.48848L26.0599 58.7321L46.652 0.983398Z"
              fill="black"
            />
          </svg>
          <svg
            viewBox="0 0 7 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.53303 0.963379H0.941406V63.7141H6.53303V0.963379Z"
              fill="black"
            />
          </svg>
          <svg
            viewBox="0 0 46 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.2452 5.9654H0.808594V0.963379H45.2941V5.9654H25.8368V63.7141H20.2452V5.9654Z"
              fill="black"
            />
          </svg>
          <svg
            viewBox="0 0 47 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.7003 39.606H5.68732V63.7346H0.0957031V0.963379H22.4622C35.1724 0.963379 39.8149 6.7034 39.8149 15.4774V25.092C39.8149 31.9595 36.9675 37.064 29.4982 38.8475L46.3763 63.7141H40.0212L23.7003 39.606ZM22.4622 5.8834H5.58418V34.5835H22.4622C30.4267 34.5835 34.1201 31.939 34.1201 25.0715V15.4569C34.1201 8.58941 30.4267 5.9449 22.4622 5.9449"
              fill="black"
            />
          </svg>
          <svg
            viewBox="0 0 46 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0163 64.6572C6.79845 64.6572 0.917969 56.7442 0.917969 46.0022V18.6756C0.917969 7.93353 6.79845 0.0205078 23.0163 0.0205078C39.2341 0.0205078 45.1146 7.93353 45.1146 18.6756V46.0022C45.1146 56.7442 39.2341 64.6572 23.0163 64.6572ZM39.5229 45.8177V18.8806C39.5229 9.94254 35.1693 5.02253 23.0163 5.02253C10.8632 5.02253 6.50959 9.92204 6.50959 18.8806V45.8177C6.50959 54.7762 10.8632 59.6757 23.0163 59.6757C35.1693 59.6757 39.5229 54.7762 39.5229 45.8177Z"
              fill="black"
            />
          </svg>
          <svg
            viewBox="0 0 37 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.23089 46.3917C6.23089 56.1907 10.5845 59.6757 22.0773 59.6757C26.7795 59.6338 31.4711 59.2225 36.108 58.4457L36.8095 63.2837C31.8081 64.2118 26.7319 64.6853 21.644 64.6982C6.37533 64.6982 0.701172 58.1997 0.701172 46.5147V18.1836C0.701172 6.49853 6.39596 0 21.644 0C26.7319 0.0128388 31.8081 0.486303 36.8095 1.41451L36.1493 6.23202C31.5123 5.45521 26.8208 5.04393 22.1186 5.00202C10.6465 5.00202 6.2722 8.48704 6.2722 18.2861L6.23089 46.3917Z"
              fill="black"
            />
          </svg>
          <svg
            viewBox="0 0 38 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.2823 64.6572C12.2101 64.6742 6.17999 63.6549 0.455078 61.6437L1.5074 57.1132C7.01427 58.7721 12.7343 59.6284 18.4887 59.6552C29.3005 59.6552 32.2511 56.4572 32.2511 48.4417C32.2511 38.7451 32.2511 37.6996 18.1172 34.5016C2.84854 31.0166 1.13601 28.3516 1.13601 15.6621C1.13601 5.41202 4.93257 0.0205078 20.7583 0.0205078C26.2348 0.0641793 31.6837 0.794277 36.9761 2.19351L36.3158 6.90853C31.2431 5.75508 26.0644 5.12309 20.8615 5.02253C8.33705 5.02253 6.64508 8.03604 6.64508 15.8466C6.64508 26.0966 6.93397 26.4861 20.2012 29.7866C36.7079 33.8866 37.7396 36.2851 37.7396 48.2367C37.7396 58.4867 34.2319 64.6367 18.1998 64.6367"
              fill="black"
            />
          </svg>
          <svg
            viewBox="0 0 53 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.5059 47.232H12.124L6.24351 63.6321H0.734375L22.7295 0.963379H30.9829L52.9987 63.7141H47.4895L41.5059 47.232ZM13.8159 42.435H39.7933L26.7943 4.18189L13.8159 42.435Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="text-3xl text ans my-10">
          <p className="uppercase pin-text tracking-widest text-slate-400">
          {language[1].vitrocsaInnovation}
          </p>
        </div>
      </section>
      <Ellipse className={"bg-black ellipse-1 z-30"} />
    </div>
  );
};

export default VitrocsaTitle;
